var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            staticClass: "header-item",
            class: { active: _vm.isModeAgentStartedNovels },
            on: { click: _vm.onClickAssociatedNovels },
          },
          [
            _vm._v(
              " " +
                _vm._s(`エージェント連携中（${_vm.associatedNovels.length}）`) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "header-item",
            class: { active: !_vm.isModeAgentStartedNovels },
            on: { click: _vm.onClickNonAssociatedNovels },
          },
          [
            _vm._v(
              " " +
                _vm._s(`未設定作品（${_vm.nonAssociatedNovels.length}）`) +
                " "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isModeAgentStartedNovels && _vm.associatedNovels.length
            ? _vm._l(_vm.associatedNovels, function (novel) {
                return _c("AgentNovelListItem", {
                  key: novel.novelId,
                  attrs: { novel: novel },
                })
              })
            : _vm.isModeAgentStartedNovels
            ? [
                _c("div", { staticClass: "empty-list" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("連携中の作品はありません。"),
                  ]),
                  _c("div", { staticClass: "message" }, [
                    _vm._v("未設定作品をNolaエージェントに連携しよう！"),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: { click: _vm.onClickAssociate },
                    },
                    [_vm._v("作品を連携する")]
                  ),
                ]),
              ]
            : !_vm.isModeAgentStartedNovels && _vm.nonAssociatedNovels.length
            ? _vm._l(_vm.nonAssociatedNovels, function (novel, index) {
                return _c("NonAgentNovelListItem", {
                  key: novel.novelId,
                  attrs: { novel: novel, isFirst: index === 0 },
                })
              })
            : [
                _c("div", { staticClass: "empty-list" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("未設定作品はありません。"),
                  ]),
                  _c("div", { staticClass: "message" }, [
                    _vm._v("作品を作成してNolaエージェントに連携しよう！"),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: { click: _vm.onClickCreateNovel },
                    },
                    [_vm._v("作品を作成する")]
                  ),
                ]),
              ],
        ],
        2
      ),
      _vm.showOnboarding && _vm.novels.length > 0
        ? _c("Onboarding", {
            attrs: {
              targetSelector: ".is-first-agent-button",
              message: "ここをタップしてエージェント<br>連携をしてみましょう！",
            },
            on: {
              "onboarding-finished": _vm.handleOnboardingFinished,
              "onboarding-close-clicked": _vm.onboardingCloseClicked,
              "onboarding-target-clicked": _vm.onboardingTargetClicked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }